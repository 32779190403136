<template>
  <v-container fluid>
    <v-list-item three-line>
      <v-list-item-content>
        <p class="display-1 text--primary">
          {{ $t("menu.unit") }}
        </p>
      </v-list-item-content>
    </v-list-item>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="allUnits"
      class=" agTable"
      :loading="loading"
      :no-results-text="$t('general.ui.not_search_found')"
      :no-data-text="$t('general.ui.not_data_found')"
      :loading-text="$t('general.notification.loadData')"
      :footer-props="{
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-text-field
            onkeypress="return (event.charCode != 34)" style="max-width: 25em;"
            v-on:keyup.86="replaceSearch" v-on:keyup.ctrl.86="replaceSearch"
            color="secondary"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('general.titles.filter')"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-dialog persistent v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn
                small
                tile
                outlined
                color="secondary"
                class="mb-2"
                v-on="on"
              >
                <v-icon small color="secondary">mdi-plus</v-icon>
                {{ $t("general.buttons.new") }}
              </v-btn>
            </template>
            <v-card :light="true" color="white">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceUnitName" v-on:keyup.ctrl.86="replaceUnitName"
                          color="secondary"
                          v-model="editedItem.unit_name"
                          :label="$t('measurementUnit.fields.name')"
                          required
                          class="required"
                          :rules="requireAndMaxRules(max)"
                          :counter="max"
                          maxlength="max"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field onkeypress="return (event.charCode != 34)"  v-on:keyup.86="replaceCode" v-on:keyup.ctrl.86="replaceCode"
                          color="secondary"
                          v-model="editedItem.code"
                          :label="$t('measurementUnit.fields.code')"
                          required
                          class="required"
                          :rules="requireAndMaxRules(maxCode)"
                          :counter="maxCode"
                          maxlength="maxCode"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-combobox :no-data-text="$t('general.fields.noDataAvailable')"
                          color="secondary"
                          :clearable="true"
                          :hide-selected="true"
                          :items="allGroupUnits"
                          :label="$t('measurementUnit.fields.group')"
                          :small-chips="false"
                          item-text="measurementUnitGroupName"
                          item-value="id"
                          persistent-hint
                          v-model="editedItem.measurementUnitGroupId"
                          required
                          class="required"
                          :rules="requireRules"
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-combobox :no-data-text="$t('general.fields.noDataAvailable')"
                          color="secondary"
                          :clearable="true"
                          :hide-selected="true"
                          :items="allUnits"
                          :label="$t('measurementUnit.fields.base_unit')"
                          :small-chips="false"
                          item-text="unit_name"
                          item-value="id"
                          persistent-hint
                          v-model="editedItem.base_measurement_unit"
                        >
                        </v-combobox>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-currency-field
                            :default-value="null"
                            :value-as-integer="false"
                            :decimal-length="decimalFormat"
                            :auto-decimal-mode="false"
                            locale="en"
                            color="secondary"
                            :label="$t('measurementUnit.fields.factor')"
                            v-model="editedItem.factor"
                        >
                        </v-currency-field>
                      </v-col>
                    </v-row>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-switch
                        v-model="editedItem.isInteger"
                        color="secondary"
                      >
                        <template v-slot:prepend>
                          <v-label class="" ref="label">
                            {{ $t("measurementUnit.fields.integer_value") }}
                          </v-label>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small tile outlined color="gray" @click="close">
                  {{ $t("general.buttons.cancel") }}
                </v-btn>
                <v-btn
                  small
                  tile
                  outlined
                  color="secondary"
                  :disabled="!valid"
                  @click="save"
                >
                  {{ $t("general.buttons.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.isInteger="{ item }">
        <v-icon small color="secondary" class="ml-4" v-if="item.isInteger">
          mdi-checkbox-marked-circle
        </v-icon>
        <v-icon small color="red" class="ml-4" v-if="!item.isInteger"></v-icon>
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon color="neuter" small class="mr-2" @click="editItem(item)"
          >mdi-pencil</v-icon
        >
        <v-icon color="neuter" small @click="deleteItemDialog(item)"
          >mdi-delete</v-icon
        >
      </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t("general.buttons.cancel") }}
            </v-btn>
            <v-btn small text color="secondary" @click="deleteItem">
              {{ $t("general.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import N_UnitsService from "@/services/N_UnitsService";
import _ from "lodash";
import i18n from "@/plugins/i18n";
import LanguajeService from "../../../services/LanguajeService";

export default {
  name: "Units",
  data() {
    return {
      // dialog
      dialog: false,
      showDialog: false,
      title: "Información",
      dialogText: "Añada un texto a este dialogo",
      valid: false,
      max: 40,
      maxCode: 10,
      editedIndex: -1,
      search: "",
      editedItem: this.getModel(),
      defaultItem: this.getModel(),
      deletingItem: {},
    };
  },
  created() {
    this.config = JSON.parse(localStorage.getItem('config'))
    this.loadDependencies();
  },
  computed: {
    ...mapGetters({
      allUnits: "units/allUnits",
      allGroupUnits: "units/allGroupUnits",
      loading: "units/loading",
      requireAndMaxRules: "general/requireAndMaxRules",
      requireRules: "general/requireRules"
    }),
    config: {
      get() {
        return this.$store.getters['general/config']
      },
      set(val) {
        this.$store.commit('general/SET_CONFIG', val)
      }
    },
    decimalFormat() {
      return this.config.company.traceability.structure.number.afterPoint !== null
          ? this.config.company.traceability.structure.number.afterPoint
          : 3
    },
    formTitle() {
      return (
        (this.editedIndex === -1
          ? i18n.t("general.titles.new")
          : i18n.t("general.titles.edit")) +
        " " +
        i18n.t("menu.unit")
      );
    },
    headers: () => [
      {
        text: i18n.t("measurementUnit.fields.name"),
        align: "left",
        value: "unit_name",
      },
      { text: i18n.t("measurementUnit.fields.code"), value: "code" },
      {
        text: i18n.t("measurementUnit.fields.group"),
        value: "measurementUnitGroupName",
      },
      {
        text: i18n.t("measurementUnit.fields.base_unit"),
        value: "baseMeasurementUnitName",
      },
      { text: i18n.t("measurementUnit.fields.factor"), value: "factor" },
      {
        text: i18n.t("measurementUnit.fields.integer_value"),
        value: "isInteger",
      },
      {
        text: i18n.t("general.fields.action"),
        value: "action",
        width: 100,
        sortable: false,
        align: "end",
      },
    ],
  },
  methods: {
    ...mapActions({
      fetchAllUnits: "units/fetchAllUnits",
      fetchAllGroupUnits: "units/fetchAllGroupUnits",
      saveUnit: "units/saveUnit",
      deleteUnit: "units/deleteUnit",
      languajeKeys: "general/getLanguajeKeys",
    }),
    getModel() {
      return {
        unit_name: "",
        code: "",
        measurementUnitGroupId: null,
        base_measurement_unit: null,
        factor: null,
        isInteger: false,
      };
    },
    loadDependencies() {
      this.fetchAllGroupUnits(this.$toast);
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.form.reset();
      }, 300);
    },
    save() {
      let item = Object.assign({}, this.editedItem);
      if (!item.id) {
        item.languageKey = item.unit_name.replace(/ /g, "_").toLowerCase();
      }
      item.measurementUnitGroupId = item.measurementUnitGroupId.id;
      if (item.base_measurement_unit) {
        item.base_measurement_unit = item.base_measurement_unit.id;
      }

      this.close();
      this.saveUnit([item, this.editedIndex, this.$toast]).finally(() => {
        this.languajeKeys([this.$toast]).finally(() => {
          this.fetchAllGroupUnits([this.$toast]);
        });
      });
    },
    editItem(item) {
      this.editedIndex = this.allUnits.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.measurementUnitGroupId = this.allGroupUnits.find(
        (x) => x.id === this.editedItem.measurementUnitGroupId
      );
      this.editedItem.base_measurement_unit = this.allUnits.find(
        (x) => x.id === this.editedItem.base_measurement_unit
      );
      this.dialog = true;
    },
    deleteItem() {
      this.dialogClose();
      this.deleteUnit([this.deletingItem.id, this.$toast]).finally(() => {
        this.fetchAllGroupUnits([this.$toast]);
      });
    },
    dialogShow(params) {
      this.title = params.title;
      this.dialogText = params.dialogText;
      this.showDialog = true;
    },
    dialogClose() {
      this.showDialog = false;
    },
    deleteItemDialog(item) {
      this.deletingItem = item;
      this.dialogShow({
        title: i18n.t("general.titles.alert"),
        dialogText: i18n.t("general.notification.deleteItems"),
      });
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceUnitName(event){
      this.editedItem.unit_name =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceCode(event){
      this.editedItem.code =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceFactor(event){
      this.editedItem.factor =  event.currentTarget.value.toString().replaceAll('"','')
    }
  },
};
</script>

<style scoped></style>
